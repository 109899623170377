import "../../packages/component/src/components/share-menu/index.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../packages/component/src/components/share-menu/index.css.ts.vanilla.css\",\"source\":\"Ll8xZmlkYnlmMCB7CiAgZGlzcGxheTogZmxleDsKICBmbGV4OiAxOwogIHdpZHRoOiAxMDAlOwogIHBhZGRpbmc6IDAgMTBweDsKICBtYXJnaW46IDA7CiAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7CiAgYWxpZ24taXRlbXM6IGNlbnRlcjsKICBwb3NpdGlvbjogcmVsYXRpdmU7Cn0KLl8xZmlkYnlmMSB7CiAgcGFkZGluZzogNHB4IDE4cHg7CiAgcGFkZGluZy1ib3R0b206IDE2cHg7CiAgd2lkdGg6IDEwMCU7Cn0KLl8xZmlkYnlmMiB7CiAgd29yZC13cmFwOiBicmVhay13b3JkOwogIGZvbnQtc2l6ZTogMTZweDsKICBtYXJnaW4tdG9wOiAxNnB4OwogIG1hcmdpbi1ib3R0b206IDE2cHg7Cn0KLl8xZmlkYnlmMyB7CiAgbWFyZ2luLXRvcDogMThweDsKfQouXzFmaWRieWY0IHsKICBkaXNwbGF5OiBmbGV4OwogIGdhcDogOXB4OwogIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47CiAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7CiAgYWxpZ24taXRlbXM6IGZsZXgtc3RhcnQ7Cn0KLl8xZmlkYnlmNSB7CiAgZGlzcGxheTogZmxleDsKICB3aWR0aDogMTAwJTsKICBmbGV4LWRpcmVjdGlvbjogY29sdW1uOwp9Ci5fMWZpZGJ5ZjYgewogIHBvc2l0aW9uOiByZWxhdGl2ZTsKfQouXzFmaWRieWY3IHsKICBkaXNwbGF5OiBmbGV4OwogIGp1c3RpZnktY29udGVudDogY2VudGVyOwogIGFsaWduLWl0ZW1zOiBjZW50ZXI7CiAgbWFyZ2luLXRvcDogMTZweDsKfQouXzFmaWRieWY4IHsKICBkaXNwbGF5OiBmbGV4OwogIGp1c3RpZnktY29udGVudDogY2VudGVyOwogIGFsaWduLWl0ZW1zOiBjZW50ZXI7CiAgcGFkZGluZzogMDsKICBib3JkZXI6IG5vbmU7Cn0KLl8xZmlkYnlmOSB7CiAgZm9udC1zaXplOiAyMHB4OwogIG1hcmdpbi1yaWdodDogMTJweDsKICB2ZXJ0aWNhbC1hbGlnbjogdG9wOwp9\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionsStyle = '_1fidbyf4';
export var buttonStyle = '_1fidbyf3';
export var containerStyle = '_1fidbyf5';
export var descriptionStyle = '_1fidbyf2';
export var exportButtonStyle = '_1fidbyf8';
export var indicatorContainerStyle = '_1fidbyf6';
export var inputButtonRowStyle = '_1fidbyf7';
export var menuItemStyle = '_1fidbyf1';
export var svgStyle = '_1fidbyf9';
export var tabStyle = '_1fidbyf0';